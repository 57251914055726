.reading-pane {
    width: 80%;
    margin: auto;
}

@media (min-width: 600px) {
    .reading-image {
        width: 50%;
        margin-left: 20px;
    }
}

@media (max-width: 600px) {
    .reading-image {
        width: 100%;
    }
}
