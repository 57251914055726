.locked {
    --main: #aaa;
}

.buttonContainer {
    color: var(--main);
    margin: 0 10px;
    padding-bottom: 2px;
    position: relative;
    overflow: hidden;
}

.buttonLine {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid var(--main);
}

.buttonAnimation {
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: 0;
    border-bottom: 2px solid white;
    transition: right .3s, left .3s;
}

.buttonContainer:hover:not(.locked) .buttonAnimation {
    right: 0;
    left: 0;
}

.buttonContainer:hover:not(.locked) .buttonText {
    color: white;
}

.buttonText {
    padding: 0 5px;
    transition: color .3s;
}
