body {
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Helvetica', 'Arial', sans-sefir, serif;
    font-size: 20px;
}

#root {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
}

#header {
    min-height: 80px;
    max-height: 80px;
    border-bottom: 1px solid #d4d4d4;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--contrast);
}

#headcenter {
    display: flex;
}

.avatar {
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background-size: contain;
}

#scroller {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

#content {
    margin: auto;
    display: flex;
}

@media (min-width: 600px) {
    #content {
        width: 80%;
    }
}

@media (max-width: 600px) {
    #content {
        width: 100%;
    }
}

#content > div {
    flex-grow: 1;
}

.button {
    cursor: pointer;
    user-select: none;
}

.button.locked {
    cursor: not-allowed;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.padding-top-2em {
    padding-top: 2em;
}

.margin-bottom-2em {
    margin-bottom: 2em;
}

.split {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.halfWidth {
    width: 50%;
}
