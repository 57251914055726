.avatar.border:after {
    content: " ";
    position: absolute;
    left: -5px;
    top: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid yellow;
    border-radius: 50%;
}

.border {
    position: relative;
}
